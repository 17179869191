import React from "react";
import HeadSeo from "../components/HeadSeo.js";
import Layout from "../components/Layout.js";
import DynamicSidebar from "../components/DynamicSidebar";
import SearchBlock from "../components/SearchBlock.js";
import BlogLists from "../components/BlogLists.js";

export const Head = () => {
  return (
    <HeadSeo
      title={`Search our Website`}
      description={
        "Quickly search here according to terms to find the best blog posts and content. You can also input multiple words when searching by topic."
      }
      seoImg={`search-icon.png`}
    ></HeadSeo>
  );
};

const SearchPage = () => {
  return (
    <>
      <Layout>
        <main>
          <div className="main-wrapper">
            <div className="primary-wrapper">
              <div className="layout-rightsidebar">
                <div className="main backpage">
                  <SearchBlock />
                  <p>
                    <br />
                    <br />
                    <br />
                  </p>

                  <h3>Popular Recent Searches</h3>
                  <BlogLists limit="3" layoutType={`grid-top`}></BlogLists>
                </div>

                {/* - - -  */}
                <DynamicSidebar onlyNews="true" />
              </div>
            </div>
          </div>
        </main>
      </Layout>
    </>
  );
};

export default SearchPage;
