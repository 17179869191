import React, { Component } from "react";
import Axios from "axios";
import { Link } from "gatsby";
import * as JsSearch from "js-search";

class SearchBlock extends Component {
  state = {
    siteList: [],
    search: [],
    searchResults: [],
    isLoading: true,
    isError: false,
    searchQuery: "",
    searchedTermSubmitted: "",
  };

  /**  */
  async componentDidMount() {
    Axios.get("/generated-content/blogs.json")
      .then((result) => {
        const siteData = result?.data;
        this.setState({ siteList: siteData });
        setTimeout(() => {
          this.rebuildIndex();
          return true;
        }, 500);
      })
      .catch((err) => {
        this.setState({ isError: true });
        console.log("====================================");
        console.log(`Something bad happened while fetching the data\n${err}`);
        console.log("====================================");
      });
  }

  /**
   * rebuilds the overall index based on the options
   */
  rebuildIndex = () => {
    const { siteList } = this.state;
    const dataToSearch = new JsSearch.Search("id");

    // dataToSearch.indexStrategy = new JsSearch.PrefixIndexStrategy();
    dataToSearch.indexStrategy = new JsSearch.AllSubstringsIndexStrategy();
    dataToSearch.sanitizer = new JsSearch.LowerCaseSanitizer();
    /**
     *
     * defines the search index
     * read more in here https://github.com/bvaughn/js-search#configuring-the-search-index
     */
    dataToSearch.searchIndex = new JsSearch.TfIdfSearchIndex("id");

    dataToSearch.addIndex("title");
    dataToSearch.addIndex("description");
    dataToSearch.addIndex("body");

    dataToSearch.addDocuments(siteList); // adds the data to be searched

    this.setState({ search: dataToSearch, isLoading: false });
  };

  stripDownDescription = (e) => {
    if (!e) return "";

    const regex = /(<([^>]+)>)/gi;
    const result = e.replace(regex, ``);
    return result.substring(0, 200);
  };

  searchData = (e) => {
    this.setState({ searchQuery: e.target.value });
  };

  // noSubmit = (e) => {
  //   e.preventDefault();
  // };

  handleSubmit = (e) => {
    e.preventDefault();

    const { search, searchQuery } = this.state;
    const queryResult = search.search(searchQuery);
    this.setState({
      searchQuery: searchQuery,
      searchResults: queryResult,
      searchedTermSubmitted: searchQuery,
    });
  };

  handleClear = () => {
    this.setState({
      searchQuery: "",
      searchedTermSubmitted: [],
      searchResults: [],
    });
  };

  render() {
    const { searchResults, searchQuery, searchedTermSubmitted } = this.state;

    const queryResults = searchResults;

    return (
      <div className="search-block">
        <form
          id="search-form"
          onSubmit={(e) => {
            this.handleSubmit(e);
          }}
        >
          <div className="input-the-search">
            <h1>Search for content on our website</h1>
            <p>
              Quickly search here according to terms to find the best blog posts
              and content. You can also input multiple words when searching by
              topic.
            </p>
            <div className="searching-stuff">
              <input
                name="searching"
                id="search"
                value={searchQuery}
                onChange={this.searchData}
                placeholder="Enter your search here"
                minLength={`3`}
              />
              <button
                id="search-button"
                type="submit"
                disabled={searchQuery.length < 3}
              >
                submit
              </button>
            </div>
            <div>
              {searchQuery.length > 0 ? (
                <button className="clear" onClick={this.handleClear}>
                  X clear
                </button>
              ) : (
                ``
              )}
              <small className={searchQuery.length < 3 ? `red` : `ok`}>
                minimum 3 characters
              </small>
            </div>
          </div>
        </form>

        <div>
          {searchedTermSubmitted.length ? (
            <div className="search-counter">
              {searchedTermSubmitted ? queryResults.length : "0"} Results Found
              {searchedTermSubmitted.length ? (
                <span
                  dangerouslySetInnerHTML={{
                    __html: ` for the word(s) <b>${searchedTermSubmitted}</b>`,
                  }}
                />
              ) : (
                false
              )}
            </div>
          ) : null}

          <div className="search-grouping">
            {queryResults
              ? queryResults.map((item, i) => {
                  return (
                    <div className="results-of-search" key={`row_${i}`}>
                      <div>
                        <span className="header-part">
                          <Link to={`${item.slug}`}> {item.title}</Link>
                        </span>
                        <p>
                          {item.body
                            ? this.stripDownDescription(item.body)
                            : false}{" "}
                          ... <a href={`${item.slug}`}>read more</a>
                        </p>
                      </div>
                    </div>
                  );
                })
              : false}
          </div>
        </div>
      </div>
    );
  }
}

export default SearchBlock;
